<template>
	<form class="form form--register form-register" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<img src="~@/assets/images/correspondent-logo--dark-text.png" alt="Correspondent Division" width="200" class="form__logo" />

			<h1 class="form-section__title">Request Access to SouthState Correspondent Services</h1>
			<p class="form-section__description"></p>

			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="First Name" id="register_first_name" name="firstName" type="text" autocomplete="given_name" :required="true" v-model="fields.firstName" />

				<text-field class="form__field" label="Last Name" id="register_last_name" name="lastName" type="text" autocomplete="family_name" :required="true" v-model="fields.lastName" />

				<text-field class="form__field" label="Email Address" id="register_email" name="email" type="email" autocomplete="email" :required="true" v-model="fields.email" />

				<text-field class="form__field" label="Confirm Email Address" id="register_email_confirm" name="email_confirm" type="email" autocomplete="email" :required="true" v-model="fields.emailConfirm" />

				<text-field class="form__field" label="City" id="register_city" name="register_city" type="text" autocomplete="city" v-model="fields.address.city" />

				<select-field class="form__field" label="State" v-model="fields.address.state" :options="states" />

				<autocomplete-organization v-model="fields.orgId" @selected="orgSelect" :required="true" class="form__field" />

				<text-field class="form__field" label="Phone" id="register_phone" name="phone" type="tel" autocomplete="tel-national" :required="true" v-model="fields.phone" />

			</div>

			<p class="form__disclaimer">Please use the Organization autocomplete field to find your organization in our system. If you do not see your organization listed, then they are not yet registered with the Resource Center. You may still register for the Resource Center. To do so, enter "Other" for your organization.</p>

			<h3 class="form-section__title">Service Subscriptions</h3>
			<div class="form-list">
				<div class="form-list-item">
					<checkbox class="form-list-item__field" label="Resource Center (included)" :disabled="true" />
					<span class="form-list-item__description">
						A whole array of templates, calculators, policies, procedures and data on a variety of banking topics.
					</span>
				</div>
				<div class="form-list-item" v-if="!showBondInsight">
					<checkbox class="form-list-item__field" label="ARC Pricing" v-model="fields.permissions['arc']" :disabled="showArc" />
					<div class="form-list-item__description">
						Our live loan pricing calculator that let's you convert fixed to floating rate loans (or the opposite) holding interest risk constant.
					</div>
				</div>
				<div class="form-list-item" v-if="showBondInsight">
					<checkbox class="form-list-item__field" label="Bond Insight" v-model="fields.permissions['bond-insight']" />
					<div class="form-list-item__description">
						Bond Accounting, Safekeeping, and Trading summary and reports
					</div>
				</div>
			</div>


			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions">
				<a-button class="button--primary form__action" type="submit" :disabled="isLoading">Send Request</a-button>
			</div>
		</div>

	</form>
</template>

<script>
import stateList from '@/assets/stateList.json'

import AButton from '@/components/AButton'
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import Checkbox from '@/components/Checkbox'

export default {
	name: 'formLogin',
	components: {
		AButton,
		AutocompleteOrganization,
		Checkbox,
		LoadingSpinner,
		SelectField,
		TextArea,
		TextField,
	},
	data: () => ({
		error: null,
		fields: {
			address: {},
			company: '',
			email: '',
			emailConfirm: '',
			firstName: '',
			lastName: '',
			orgId: '',
			notes: '',
			phone: '',
			permissions: { },
		},
		isLoading: false,
		states: stateList,
	}),
	computed: {
		loading() {
			return this.$store.getters['misc/loading']
		},
		showBondInsight() {
			return !!this.$route.query.showbi
		},
		showArc() {
			return !!this.$route.query.arc
		},
	},
	methods: {
		orgSelect(data) {
			this.fields.orgId = data.value
		},
		submitForm(fields) {
			if (fields.email != fields.emailConfirm) return this.error = `Email Addresses do not match`
			if (!fields.orgId) return this.error = `Please select an Organization. If yours can not be found, please contact us.`

			this.error = null

			this.isLoading = true
			this.$store.dispatch('registration/create', fields)
				.then((res) => {
					if (res && res.success) {
						Object.keys(this.fields).forEach(k => this.fields[k] = '')
						this.$confirm(`You have successfully registered! We will review your information and get back to you.`, undefined, {
							title: `Registration Complete`,
							acceptLabel: `Close`,
							showCancel: false,
						})
					}
				})
				.catch((error) => {
					this.error = error.message || error
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
	watch: {
		'$route.query': {
			immediate: true,
			handler(newVal) {
				if (newVal.arc) {
					this.fields.permissions.arc = !!newVal.arc
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
.form {
	&__fields {
		display: flex;
		flex-direction: column;
	}

	&__field {
		@include modules.gutter('margin-bottom');
	}

	&__actions {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}

	&__action {
		margin-bottom: 1em;
	}

	&-row {
		&--col-2 {
			@include modules.media-query-max-width('desktop-xs') {
				grid-template-columns: 1fr;
			}
		}
	}

	&-list {
		@include modules.gutter('margin-bottom');
	}

	&-list-item {
		@include modules.gutter('margin-bottom');

		&__description {
			color: modules.color_('text', 'base');
			display: inline-block;
			margin-left: calc(18px + 1em);
		}

		&__field {
			font-weight: 500;
			margin-bottom: 0.5em;
		}
	}

	&-section {
		padding-top: 0;

		&__title {
			margin-top: 0;
		}

		&__title {
			@include modules.media-query-min-width('desktop-sm') {
				max-width: 80%;
			}
		}
	}

	&__logo {
		margin-bottom: 1em;

		@include modules.media-query('desktop') {
			display: none;
		}
	}

	&__disclaimer {
		@include modules.fontSize(12px);
		color: modules.color_('text');
		@include modules.gutter('margin-bottom', 1.5);
	}
}
</style>
