<template>
	<div class="auth-register">
		<form-register class="auth-register__form" />

		<div class="auth-register__header">
			<div class="auth-register__header-label"><span>Already have an account?</span></div>
			<router-link :to="{ name: 'login' }" class="button button--unelevated button--secondary button--medium">Login</router-link>
		</div>
	</div>
</template>

<script>
import FormRegister from '@/components/FormRegister'

export default {
	name: 'AuthRegister',
	components: {
		FormRegister,
	},
	metaInfo: () => ({
		title: 'Request Access',
	}),
}
</script>

<style scoped lang="scss">
.auth-register {
	display: flex;
	flex-direction: column;
	flex: auto;

	&__form {
		@include modules.gutter('padding');
		max-width: 840px;
		width: 100%;

		@include modules.media-query('phone') {
			@include modules.gutter('padding', 0);
		}
	}

	&__header {
		@include modules.gutter('margin-bottom');
		@include modules.gutter('padding', 2);
		@include modules.gutter('padding-top', 0);

		display: flex;
		text-align: center;
		flex-direction: column;

		@include modules.media-query('desktop') {
			max-width: 750px;
			box-sizing: content-box;
		}

		&-label {
			@include modules.gutter('margin-bottom');
			position: relative;

			&:before {
				content: "";
				background: #ccc;
				display: block;
				height: 1px;
				width: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			span {
				//@include modules.gutter('padding-right');
				background: #fff;
				padding: 18px;
				position: relative;
			}
		}

		@include modules.media-query('phone') {
			align-items: center;
		}
	}
}
</style>
