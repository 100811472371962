<template>
	<div class="partial partial--auth-verify auth-verify">
		<form-verify class="auth-verify__form" />

		<div class="auth-verify__header">
			<div class="auth-verify__header-label"><span>Having issues? Try to log in again.</span></div>
			<router-link :to="{ name: 'login' }" class="button button--unelevated button--secondary button--medium">Login</router-link>
		</div>
	</div>
</template>

<script>
import FormVerify from '@/components/FormVerify'

export default {
	name: 'PartialAuthVerify',
	components: {
		FormVerify,
	},
	metaInfo: () => ({
		title: 'Verify Account',
	}),

}
</script>

<style scoped lang="scss">
.auth-verify {
	&__form {
		@include modules.gutter('padding');
		max-width: 640px;
		width: 100%;

		@include modules.media-query('phone') {
			@include modules.gutter('padding', 0);
		}

	}

	&__header {
		@include modules.gutter('margin-bottom');
		@include modules.gutter('padding', 2);

		display: flex;
		text-align: center;
		flex-direction: column;

		@include modules.media-query('desktop') {
			max-width: 750px;
			box-sizing: content-box;
		}

		&-label {
			@include modules.gutter('margin-bottom');
			position: relative;

			&:before {
				content: "";
				background: #ccc;
				display: block;
				height: 1px;
				width: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			span {
				//@include modules.gutter('padding-right');
				background: #fff;
				padding: 18px;
				position: relative;
			}
		}

		@include modules.media-query('phone') {
			align-items: center;
		}
	}
}
</style>
