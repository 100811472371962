<template>
	<form class="form form--verify verify" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<img src="~@/assets/images/correspondent-logo--dark-text.png" alt="Correspondent Division" width="200" class="form__logo" />

			<h1 class="form-section__title">Verify Your Account</h1>

			<div class="form-row" v-if="otpQrCode">
				<p class="form__description">Scan the QR code below in your authentication app (such as Google Authenticator) to register your two-factor authentication method.</p>
				<img :src="otpQrCode" />
			</div>
			<div class="form-row">
				<p class="form__description">Enter the verification code from your authentication app.</p>
			</div>

			<div class="form-row verify-digits">
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" :hide-counter="true" class="form__field form__field--xl form__field--center" v-model="fields.digits[0]" @keyup="nextDigit" @paste.prevent="onPaste" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" :hide-counter="true" class="form__field form__field--xl form__field--center" v-model="fields.digits[1]" @keyup="nextDigit" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" :hide-counter="true" class="form__field form__field--xl form__field--center" v-model="fields.digits[2]" @keyup="nextDigit" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" :hide-counter="true" class="form__field form__field--xl form__field--center" v-model="fields.digits[3]" @keyup="nextDigit" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" :hide-counter="true" class="form__field form__field--xl form__field--center" v-model="fields.digits[4]" @keyup="nextDigit" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" :hide-counter="true" class="form__field form__field--xl form__field--center" v-model="fields.digits[5]" />
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions">
				<a-button class="button--primary form__action" type="submit" :disabled="isLoading || fields.digits.length < 6">Verify</a-button>
			</div>
		</div>
	</form>
</template>

<script>
import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'

export default {
	name: 'FormVerify',
	components: {
		AButton,
		LoadingSpinner,
		TextField,
	},
	data: () => ({
		error: null,
		fields: {
			digits: [],
			code: ``,
		},
		isLoading: false,
	}),
	methods: {
		submitForm(fields) {
			this.error = null
		}
	},
	computed: {
		verify() {
			return this.$store.getters['user/verify']
		},
		otpQrCode() {
			return this.verify.otpQrCode
		},
	},
	methods: {
		nextDigit(evt) {
			if (!evt.key.match(/[0-9]/)) return
			let nextInput =  evt.target.parentNode.nextElementSibling.querySelector('input')
			this.$nextTick(() => {
				nextInput.select()
			})
		},
		onPaste(evt) {
			const val = evt.clipboardData.getData('Text')
			if (val) {
				this.fields.digits = val.split('')
			}
		},
		submitForm(fields) {
			if (!fields.digits.length == 6) return
			fields.code = fields.digits.join(``)

			this.error = null

			this.isLoading = true

			this.$store.dispatch('user/verify', {code: fields.code} )
				.then((res) => {
					let { redirect_uri, token, needsOtp } = res
					if (redirect_uri && token) {
						let queryParams = new URLSearchParams()
						queryParams.set('redirect_uri', redirect_uri)
						queryParams.set('token', token)

						window.location.href = `${redirect_uri}?${queryParams.toString()}`
					} else {
						if (this.$route.query && this.$route.query.from) {
							this.$router.push({ path: this.$route.query.from })
						} else {
							this.$router.push({ name: 'main' })
						}
					}
				})
				.catch((error) => {
					this.error = error.message || error
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
}
</script>

<style scoped lang="scss">
.form {
	&__actions {
		align-items: flex-start;
	}

	&__logo {
		display: none;
		@include modules.media-query('phone') {
			display: block;
			margin-bottom: 1rem;
		}
	}
}
.form-section {
	&__title {
		margin-top: 0;
	}
}

.verify-digits {
	display: grid;
	grid-template-columns: repeat(6, minmax(40px, 80px) );
	@include modules.media-query('phone') {
		column-gap: 0.5rem;
	}
}
</style>
