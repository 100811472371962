<template>
	<form class="form form--login form-login" @submit.prevent.stop="submitForm(formFields)">
		<div class="form__loader" v-if="loading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<img src="~@/assets/images/correspondent-logo--dark-text.png" alt="Correspondent Division" width="200" class="form__logo" />

			<h1 class="form-section__title">Sign in to Correspondent Services</h1>
			<!-- <p class="form-section__description">SouthState Bank Correspondent Division provides convenient access to resources developed to improve your processes and strategies.</p> -->

			<div class="form-row">
				<text-field class="form__field" label="Email Address" id="login_email" name="email" type="email" autocomplete="email" :required="true" v-model="fields.email" />
			</div>
			<div class="form-row">
				<text-field class="form__field" label="Password" id="login_password" name="password" type="password" autocomplete="password" :required="true" v-model="fields.password" />
			</div>

			<div class="form-row alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions">
				<a-button class="button--primary form__action" type="submit" :disabled="loading">Sign In</a-button>
				<a @click="lostPasswordConfirm(fields)" class="button button--primary button--dark form__action">Forgot Password?</a>
			</div>
		</div>

	</form>
</template>

<script>
import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'

export default {
	name: 'formLogin',
	components: {
		AButton,
		LoadingSpinner,
		TextField,
	},
	data: () => ({
		fields: {
			email: '',
			password: '',
		},
		error: undefined,
		loading: false,
	}),
	computed: {
		formFields() {
			const { app, redirect_uri } = this.$route.query
			const { email, password } = this.fields

			return {
				email,
				password,
				app,
				redirect_uri,
			}
		},
	},
	methods: {
		submitForm(fields) {
			this.error = undefined
			this.loading = true

			let action = this.$route.query.emailUpdate ? 'user/confirm' : 'user/login'

			this.$store.dispatch(action, fields).then((res) => {
				let { redirect_uri, token, needsOtp } = res

				if (needsOtp && token) {
					this.$router.push({ name: 'verify', query: this.$route.query, queryParams: this.$route.queryParams})
				} else if (redirect_uri && token) {
					let queryParams = new URLSearchParams()
					queryParams.set('redirect_uri', redirect_uri)
					queryParams.set('token', token)

					window.location.href = `${redirect_uri}?${queryParams.toString()}`
				} else {
					if (this.$route.query && this.$route.query.from) {
						this.$router.push({ path: this.$route.query.from })
					} else {
						this.$router.push({ name: 'main' })
					}
				}
			}).catch((error) => {
				this.error = error.message || error
			}).finally(() => {
				this.loading = false
			})
		},
		lostPasswordConfirm(fields) {
			this.$confirm(`This will send you an email with instructions about how to reset your password.`,
				() => {
					this.lostPassword(fields)
				},
				{
					title: `Send Password Reset?`,
					acceptLabel: `Yes, Send`,
				},
			)
		},
		lostPassword(fields) {
			this.error = null
			this.loading = true
			if (!fields.email) {
				this.loading = false
				return this.error = `Please enter an email address`
			}

			this.$store.dispatch('user/recover', fields.email).then(() => {
				this.$notice(`You have been sent an email with password recovery instructions.`)
			}).catch(error => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},
	},
}
</script>

<style scoped lang="scss">
.form {
	&__fields {
		display: flex;
		flex-direction: column;
	}

	&__field {
		@include modules.gutter('margin-bottom');
	}

	&__actions {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 540px;
		@include modules.media-query('desktop') {
			max-width: 540px;
		}

		@include modules.media-query('phone') {
			flex-direction: column;
		}
	}

	&__action {
		margin-bottom: 1em;
	}

	&-row {
		@include modules.media-query('desktop') {
			max-width: 540px;
		}
	}

	&-section {
		padding-top: 0;

		&__title {
			margin-top: 0;
		}
	}

	&__logo {
		margin-bottom: 1em;

		@include modules.media-query('desktop') {
			display: none;
		}
	}
}
</style>
