<template>
	<div class="partial partial--auth-login auth-login">

		<form-login class="auth-login__form" />

		<div class="auth-login__header">
			<div class="auth-login__header-label"><span>Don't have an account?</span></div>
			<router-link :to="{ name: 'register' }" class="button button--unelevated button--secondary button--medium">Request Access</router-link>
		</div>
	</div>
</template>

<script>
import FormLogin from '@/components/FormLogin'

export default {
	name: 'AuthLogin',
	components: {
		FormLogin,
	},
}
</script>

<style scoped lang="scss">
.auth-login {
	display: flex;
	max-width: 840px;
	flex-direction: column;

	&__form {
		@include modules.gutter('padding');
		width: 100%;

		@include modules.media-query('phone') {
			@include modules.gutter('padding', 0);
		}
	}

	&__header {
		@include modules.gutter('margin-bottom');
		@include modules.gutter('padding', 2);
		@include modules.gutter('padding-top', 0);
		display: flex;
		text-align: center;
		flex-direction: column;

		@include modules.media-query('desktop') {
			max-width: 540px;
			box-sizing: content-box;
		}

		&-label {
			@include modules.gutter('margin-bottom');
			position: relative;

			&:before {
				content: "";
				background: #ccc;
				display: block;
				height: 1px;
				width: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			span {
				//@include modules.gutter('padding-right');
				background: #fff;
				padding: 18px;
				position: relative;

				@include modules.media-query('phone') {
					background: none;
				}
			}
		}

		@include modules.media-query('phone') {
			align-items: center;
		}
	}
}
</style>
