<template>
	<form class="form form--reset reset" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<img src="~@/assets/images/correspondent-logo--dark-text.png" alt="Correspondent Division" width="200" class="form__logo" />

			<h1 class="form-section__title">Set Your New Password</h1>
			<p class="form-section__description"></p>

			<div class="form-row">
				<text-field class="form__field" label="New Password" name="new_password" type="password" :required="true" v-model="fields.password" />
				<div class="form__hint">
					Your password must be a minimum 8 characters and include three of these four character sets: uppercase, lowercase, numbers and special characters.
				</div>
			</div>

			<div class="form-row">
				<text-field class="form__field" label="Confirm New Password" name="password_confirm" type="password" :required="true" v-model="fields.passwordConfirm" />
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions">
				<a-button class="button--primary form__action" type="submit" :disabled="isLoading">Reset Password</a-button>
				<router-link :to="{ name: 'login' }" class="button button--primary button--dark form__action">Back to Login</router-link>
			</div>
		</div>

	</form>
</template>

<script>
import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'

export default {
	name: 'FormReset',
	components: {
		AButton,
		LoadingSpinner,
		TextField,
	},
	data: () => ({
		error: null,
		fields: {},
		isLoading: false,
	}),
	methods: {
		submitForm(fields) {
			this.error = null

			if (!fields.password || !fields.passwordConfirm) {
				return this.error = `All fields are required`
			}
			if ( !fields.password.match(/[a-z]/g) ) {
				return this.error = `New password must contain lowercase letters`
			}
			if ( !fields.password.match(/[A-Z]/g) ) {
				return this.error = `New password must contain uppercase letters`
			}
			if ( !fields.password.match(/[0-9]/g) && !fields.password.match(/[^a-zA-Z\d]/g) ) {
				return this.error = `New password must contain number or special character`
			}
			if (fields.password.length < 8) {
				return this.error = `New password must be 8 or more characters`
			}
			if (fields.password !== fields.passwordConfirm) {
				return this.error = 'Password confirmation does not match'
			}

			const data = {
				password: fields.password,
				passwordConfirm: fields.passwordConfirm,
				token: this.$route.query.token,
			}

			this.isLoading = true
			this.$store.dispatch('user/reset', data).then(() => {
				this.fields = {}
				this.$notice(`Your password has been reset. Please login again.`)
				this.$router.push({ name: 'login' })
			}).catch(error => {
				if (error.message.match(/Authorization token cannot be verified\./ig)) {
					this.error = `Your password authorization token has expired.  Please select "Forgot Password?" on the login screen to request another reset link.`
				} else {
					this.error = error
				}
			}).finally(() => {
				this.isLoading = false
			})
		},
	},
}
</script>

<style scoped lang="scss">
.form {
	&__fields {
		display: flex;
		flex-direction: column;
	}

	&__field {
		@include modules.gutter('margin-bottom');
	}

	&__actions {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}

	&__action {
		margin-bottom: 1em;
	}

	&-row {
		@include modules.media-query('desktop') {
			max-width: 70%;
		}
	}

	&__logo {
		margin-bottom: 1em;

		@include modules.media-query('desktop') {
			display: none;
		}
	}
}
</style>
