<template>
	<div class="autocomplete-field">
		<text-field class="autocomplete-field__field" :label="label" v-bind="attrs" @input="input">
			<span slot="post" class="autocomplete-field__loading" v-if="loading"><ion-icon name="reload-circle-outline" /></span>
		</text-field>
		<ul :id="id" class="mdc-list" :class="{'mdc-list--two-line': twoLine}" role="listbox" v-show="options.length && attrs.value && !selected">
			<li
				class="mdc-list-item"
				role="option"
				:key="item.value"
				:tabindex="key"
				v-for="(item, key) in options"
				>
				<span class="mdc-list-item__ripple"></span>
				<span class="mdc-list-item__text">
					<span :class="{'mdc-list-item__primary-text': twoLine }" v-html="item.label"></span>
					<span class="mdc-list-item__secondary-text" v-html="item.alt" v-if="item.alt && twoLine"></span>
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { MDCList } from '@material/list'
import { v4 } from 'uuid'
import TextField from '@/components/TextField'
import { debounce } from 'lodash'

export default {
	name: 'AutocompleteField',
	components: {
		TextField,
	},
	data: () => ({
		list: null,
		selected: null,
	}),
	props: {
		id: {
			type: String,
			default: v4(),
		},
		label: String,
		options: {
			type: Array,
			default: () => [],
		},
		loading: Boolean,
		twoLine: Boolean,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
	},
	mounted() {
		this.list = new MDCList(this.$el.querySelector('.mdc-list'))
		this.list.singleSelection = true
		this.list.listen('MDCList:action', (idx) => {
			this.$emit('select', this.options[this.list.selectedIndex])
			this.selected = true
		})
	},
	methods: {
		input: debounce(function (value) {
			this.selected = null
			this.$emit('input', value)
		}, 250),
	},
}
</script>

<style scoped lang="scss">
@use '@material/list';
@include list.core-styles;

.mdc-list {
	background-color: modules.color_('background');
	border: 1px solid modules.color_('border');
	border-radius: modules.$border-radius;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin-top: -3px;
	position: absolute;
	width: 100%;
	z-index: 3;
}

.autocomplete-field {
	position: relative;
	width: 100%;

	&__field {
		width: 100%;
	}

	&__loading {
		animation: spin 1000ms linear infinite;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 22px;
		width: 22px;
		transform-origin: 50% 50%;
		right: 0.5em;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
</style>
