<template>
	<div class="view view--auth auth">
		<div class="auth__body">
			<router-view class="auth__sidebar" name="sidebar" />
			<router-view class="auth__content" name="content" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'viewAuth',
	mounted() {
		if (this.$route && this.$route.query && this.$route.query.logout) {
			this.$store.dispatch('user/logout')
			let redirect = this.$route.query.redirect_uri
			if (redirect) {
				window.location.href = redirect
			} else {
				this.$router.replace({ name: 'login' })
			}
		}
	},
}
</script>

<style scoped lang="scss">
.auth {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	width: 100%;

	&__body {
		display: flex;
		flex: 1;
		flex-direction: column;

		@include modules.media-query('desktop') {
			flex-direction: row;
		}
	}

	&__sidebar {
		flex: 1;
		max-width: 300px;
		width: 100%;

		@include modules.media-query-min-width('desktop-sm') {
			max-width: 472px;
		}
	}

	&__content {
		@include modules.gutter('padding');
		flex: 1;
	}
}
</style>
