<template>
	<div class="auth-sidebar">
		<div class="auth-sidebar__header">
			<router-link :to="{ name: 'login' }"><img src="~@/assets/images/correspondent-logo--white-text.png" alt="Correspondent Division" width="280" /></router-link>
		</div>
		<div class="auth-sidebar__body">&nbsp;</div>
		<div class="auth-sidebar__footer">
			<nav class="auth-sidebar__nav" role="navigation">
				<span class="auth-sidebar__nav-item">&copy; Copyright {{ year }}</span>
				<!-- <router-link :to="{ name: 'privacy' }" class="auth-sidebar__nav-item">Privacy Policy</router-link> -->
				<a href="https://southstatebank.com/global/privacy-notice/california-consumer-privacy-notice" target="_blank"  class="auth-sidebar__nav-item">CCPA</a>
			</nav>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AuthSidebar',
	computed: {
		year() {
			return (new Date()).getFullYear()
		},
	},
}
</script>

<style scoped lang="scss">
.auth-sidebar {
	@include modules.gutter('padding');
	background-color: modules.color_('primary');
	display: none;
	flex-direction: column;
	position: relative;

	@include modules.media-query('desktop') {
		display: flex;
	}

	&:after {
		background-image: url(~@/assets/images/bg__auth.jpg);
		background-size: cover;
		content: ' ';
		display: block;
		opacity: 0.3;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	&__header {
		position: relative;
		z-index: 4;

		img {
			max-width: 90%;
		}
	}

	&__body {
		flex: 1;
		position: relative;
		z-index: 4;
	}

	&__footer {
		position: relative;
		z-index: 4;
	}

	&__nav {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		text-align: left;

		@include modules.media-query('desktop') {
			flex-direction: row;
			align-items: center;
		}

		&-item {
			color: modules.color_('primary', 'text');
			font-size: 14px;
			text-decoration: none;
			margin-bottom: 5px;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}
</style>
