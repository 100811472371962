<template>
	<div class="partial partial--auth-reset auth-reset">
		<div class="auth-reset__header">
			<span>Don't have an account? &nbsp;</span>
			<router-link :to="{ name: 'register' }" class="button button--unelevated button--secondary button--medium">Request Access</router-link>
		</div>

		<form-reset class="auth-reset__form" />
	</div>
</template>

<script>
import FormReset from '@/components/FormReset'

export default {
	components: {
		FormReset,
	}
}
</script>

<style scoped lang="scss">
.auth-reset {

	display: flex;
	flex-direction: column;

	&__form {
		@include modules.gutter('padding');
		flex: 1;
		max-width: 620px;
		width: 100%;

		@include modules.media-query('phone') {
			@include modules.gutter('padding', 0);
		}
	}

	&__header {
		@include modules.gutter('margin-bottom');

		text-align: right;

		span {
			@include modules.gutter('padding-right');
			flex: 1;
		}

		@include modules.media-query('phone') {
			align-items: center;
		}
	}
}
</style>
